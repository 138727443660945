import React from 'react';
import styled from 'styled-components';
import Logo, { LOGO_COLOR } from './Logo';
import pinterestIcon from '../assets/images/icons/pinterest.svg';
import linkedIcon from '../assets/images/icons/linkedin.svg';
import youtubeIcon from '../assets/images/icons/youtube.svg';
import instagramIcon from '../assets/images/icons/instagram-w.svg';
import {
  LINKEDIN_URL,
  PINTEREST_URL,
  INSTAGRAM_URL,
  YOUTUBE_URL,
} from '../utils/constants';

const FooterStyles = styled.section`
  background-color: var(--charcoal);
  color: var(--white);
  min-width: 320px;
  height: 12rem;
  padding: 1rem 3rem;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr min-content; // align text at the bottom
  align-items: center;

  .logo-container {
    display: grid;
    align-self: stretch; // take entire height
  }
  .logo {
    display: flex;
    align-items: center;
    gap: 1.6rem;
  }
  .footer-label {
    letter-spacing: 0.8px;

    @media (max-width: 500px) {
      display: none;
    }
  }
  .copywrite {
    font-size: 1.2rem;
    grid-column: 1 / -1;
  }
  .icons-container {
    align-self: flex-end;
    text-align: end;
  }
  .icons {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }
  .icon {
    width: 4rem;
    height: 4rem;
    flex-shrink: 0;
  }
`;
export default function Footer() {
  return (
    <FooterStyles>
      <div className="logo-container">
        <div className="logo">
          <Logo color={LOGO_COLOR.WHITE} />
          <p className="footer-label">Let’s connect!</p>
        </div>
      </div>
      <div className="icons-container">
        <p className="footer-label">Follow me 👇</p>
        <div className="icons">
          <a href={LINKEDIN_URL}>
            <img src={linkedIcon} alt="linkedin icon" className="icon" />
          </a>
          <a href={INSTAGRAM_URL}>
            <img src={instagramIcon} alt="instagram icon" className="icon" />
          </a>
          <a href={YOUTUBE_URL}>
            <img src={youtubeIcon} alt="youtube icon" className="icon" />
          </a>
          <a href={PINTEREST_URL}>
            <img src={pinterestIcon} alt="pinterest icon" className="icon" />
          </a>
        </div>
      </div>
      <p className="copywrite p--light">
        &copy; 2021 cocoduan All rights reserved.
      </p>
    </FooterStyles>
  );
}
