import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #CB6453;
    --beige: #F9F7F3;
    --white: #FFFFFF;
    --black: #2B2B2B;
    --black--0: #000000;
    --charcoal: #252525;
    --dark: #3B3B3B;
    --grey: #EFEFEF;
    --grey--dark: #878A8F;
    --grey--light: #979797;
    --brown--dark: #606060;
    --champaign: #F4EFEB;
    --olive: #635F47;

    --bp-largest: 1200px;     // 1200px
    --bp-large: 1100px;    // 1100px
    --bp-medium: 900px;   // 900px
    --bp-small: 600px;     // 600px
    --bp-smallest: 500px; // 500px
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  html {
    font-size: 62.5%;
    box-sizing: border-box;

    @media (min-width: 1680px) {
      font-size: 87.5%;
    }
    @media (max-width: 1100px) {
      font-size: 50%;
    }
  }
  body {
    font-size: 1.6rem;
  }
  img {
    max-width: 100%;
  }
`;
export default GlobalStyles;
