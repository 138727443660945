import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Logo, { LOGO_COLOR } from './Logo';
import logoWord from '../assets/images/word-logo.svg';

const NavStyles = styled.nav`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 10rem; /* if changed, must update padding-top for Layout as well */
  padding: 2rem 4rem;
  font-size: 0;
  background-color: var(--beige);
  overflow: hidden;

  @media (max-width: 500px) {
    padding-left: 2rem;
    padding-right: 1rem;
  }

  &.sticky {
    box-shadow: 0 2px 2px #e3e3e3;
    opacity: 0.95;
    height: 7rem;
  }

  h4 {
    font-size: 1.8rem;
  }
  p {
    font-size: 1.4rem;
  }
  a {
    text-decoration: none;
    color: inherit; // do not change color for :visited
    h4,
    p {
      cursor: pointer;
    }

    &:hover {
      color: var(--red);
    }
  }
  ul {
    padding: 0;
    display: flex;
    align-items: center;

    li {
      text-align: center;
      flex: 0 0 8rem;

      &:not(:last-child) {
        margin-right: 2rem;
        @media (max-width: 500px) {
          margin: 0;
        }
      }
      &:nth-child(1) {
        text-align: left;
        flex: 0 0 10rem;
        margin-right: auto;
      }
    }
  }
  .link__home {
    display: flex;
    flex-direction: column;
    transition: all 0.2s;

    .logo-bw {
      display: block;
    }
    .logo-color {
      display: none;
    }

    &:hover {
      .logo-bw {
        display: none;
      }
      .logo-color {
        display: block;
      }
    }
  }

  &.sticky .link__home {
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }

  .link__home-label {
    margin-top: 4px;
    margin-left: 2px;
    width: 4rem;
  }
`;

export default function Nav({ sticky }) {
  return (
    <NavStyles className={sticky ? 'sticky' : ''}>
      <ul>
        <li>
          <Link to="/" className="link__home">
            <Logo color={LOGO_COLOR.BLACK} styleName="logo-bw" />
            <Logo color={LOGO_COLOR.COLORED} styleName="logo-color" />
            <img src={logoWord} alt="C / D" className="link__home-label" />
          </Link>
        </li>
        <li>
          <Link to="/#work">
            <h4 className="h--bold">Work</h4>
            {sticky ? '' : <p className="p--light">Experiences</p>}
          </Link>
        </li>
        <li>
          <Link to="/#design">
            <h4 className="h--bold">Design</h4>
            {sticky ? '' : <p className="p--light">Freelance</p>}
          </Link>
        </li>
        <li>
          <Link to="/#blog">
            <h4 className="h--bold">Blogs</h4>
            {sticky ? '' : <p className="p--light">Own vibes</p>}
          </Link>
        </li>
      </ul>
    </NavStyles>
  );
}
