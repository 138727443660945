import { createGlobalStyle } from 'styled-components';

const Animations = createGlobalStyle`
  @keyframes moveInLeft {
    0% {
      opacity: 0;
      transform: translateX(-10rem);
    }
    80% {
      transform: translateX(1rem);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }
  @keyframes moveInRight {
    0% {
      opacity: 0;
      transform: translateX(10rem);
    }
    80% {
      transform: translateX(-1rem);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes moveInBottom {
    0% {
      opacity: 0;
      transform: translateY(5rem);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }
  @keyframes moveInUp {
    0% {
      opacity: 0;
      transform: translateY(-5rem);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }
  @keyframes fadeInView {
    0% {
      transform: translate3d(0,30px,0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0,0,0);
      opacity: 1;
    }
  }
`;
export default Animations;
