import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import Footer from './Footer';
import Nav from './Nav';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Animations from '../styles/Animations';

export default function Layout({ children }) {
  const [sticky, setSticky] = useState(false);
  return (
    <>
      <GlobalStyles />
      <Typography />
      <Animations />
      <Waypoint
        onEnter={() => setSticky(false)}
        onLeave={() => setSticky(true)}
      >
        <div>
          <Nav sticky={sticky} />
        </div>
      </Waypoint>
      <div style={{ paddingTop: '10rem', minWidth: '320px' }}>{children}</div>
      <Footer />
    </>
  );
}
