import React from 'react';
import styled from 'styled-components';
import logoB from '../assets/images/logo-bw.svg';
import logoW from '../assets/images/logo-w.svg';
import logoColored from '../assets/images/logo-colored.svg';

export const LOGO_COLOR = {
  WHITE: 'white',
  BLACK: 'black',
  COLORED: 'colored',
};
const COLOR_TO_CLASS = {
  white: 'logo--w',
  black: 'logo--b',
  colored: 'logo--color',
};
const LogoStyles = styled.div`
  width: 4rem;
  height: 4rem;
  background-size: cover;

  &.logo--w {
    background-image: url(${logoW});
  }
  &.logo--b {
    background-image: url(${logoB});
  }
  &.logo--color {
    background-image: url(${logoColored});
  }
`;
export default function Logo({ color, styleName }) {
  return (
    <LogoStyles
      className={`logo ${styleName || ''} ${COLOR_TO_CLASS[color]}`}
    />
  );
}
