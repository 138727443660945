import { createGlobalStyle } from 'styled-components';
import avantGardeStdBk from '../assets/fonts/ITCAvantGardeStd-Bk.woff';
import avantGardeStdBold from '../assets/fonts/ITCAvantGardeStd-Bold.woff';
import avantGardeStdMd from '../assets/fonts/ITCAvantGardeStd-Md.woff';
import avenirNext from '../assets/fonts/AvenirNext-Regular.woff';
import avenirNextMedium from '../assets/fonts/AvenirNext-Medium.woff';
import avenirNextLight from '../assets/fonts/AvenirNext-Light.woff';
import avenirNextBold from '../assets/fonts/AvenirNext-Bold.woff';

const Typography = createGlobalStyle`
  @font-face {
    font-family: AvantGardeGothic;
    src: url(${avantGardeStdBk});
  }
  @font-face {
    font-family: AvantGardeGothicMedium;
    src: url(${avantGardeStdMd});
  }
  @font-face {
    font-family: AvantGardeGothicBold;
    src: url(${avantGardeStdBold});
  }
  @font-face {
    font-family: AvenirNext;
    src: url(${avenirNext});
  }
  @font-face {
    font-family: AvenirNextMedium;
    src: url(${avenirNextMedium});
  }
  @font-face {
    font-family: AvenirNextLight;
    src: url(${avenirNextLight});
  }
  @font-face {
    font-family: AvenirNextBold;
    src: url(${avenirNextBold});
  }

  html {
    font-family: AvenirNext, Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }

  p {
    line-height: 2.4rem;
  }

  h1,h2,h3,h4,h5,h6,p {
    font-weight: normal;
    margin: 0;
    cursor: default;
  }

  h2 {
    font-size: 4.8rem;
    letter-spacing: 0.8px;
  }

  h3 {
    font-size: 3.2rem;
    letter-spacing: 0.8px;
  }

  h4 {
    font-size: 2rem;
    letter-spacing: 0.8px;
  }

  h5 {
    font-size: 1.8rem;
    letter-spacing: 0.5px;
  }

  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .h--bold {
    font-family: AvantGardeGothicBold, Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .h--medium {
    font-family: AvantGardeGothicMedium, Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .h--regular {
    font-family: AvantGardeGothicBook, Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .p--medium {
    font-family: AvenirNextMedium, Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .p--light {
    font-family: AvenirNextLight, Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .p--bold {
    font-family: AvenirNextBold, Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .center {
    text-align: center;
  }
`;
export default Typography;
